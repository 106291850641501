import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// // @mui
import { toast } from 'react-toastify';

// material
import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import { createBlog, updateBlog, uploadBlogFile } from '../../../store/api/api';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddBlog({ getBlogs, showModal, id, toggleModal, edit }) {
  const [selectedBlogImage, setSelectedBlogImage] = useState(null);
  const [blogImageUrl, setBlogImageUrl] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (selectedBlogImage) {
      setBlogImageUrl(URL.createObjectURL(selectedBlogImage));
    }
  }, [selectedBlogImage]);
  const BlogSchema = Yup.object().shape({
    title: Yup.string().required('title required'),
    description: Yup.string().required('description required'),
    blogImage: Yup.string().required('blog Image required'),
  });
  const inputValues = [
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      value: edit ? edit.title : '',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      value: edit ? edit.description : '',
    },
    {
      name: 'blogImage',
      label: 'Blog Image',
      type: 'file',
      value: edit && edit.image ? edit.image.medium : '',
    },
  ];
  const [defaultValues, setDefaultValues] = useState(
    edit
      ? {
          title: edit.title,
          description: edit.description,
          blogImage: edit.image,
        }
      : {
          title: '',
          description: '',
          blogImage: '',
        }
  );
  const methods = useForm({
    resolver: yupResolver(BlogSchema),
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const onSubmit = async (data) => {
    setSubmitLoading(true);
    const submitBlogData = new FormData();
    const jsonData = {
      title: data.title,
      description: data.description,
    };
    if (selectedBlogImage) submitBlogData.append(`file`, selectedBlogImage);
    if (edit) {
      updateBlog(edit._id, jsonData)
        .then(async (res) => {
          if (selectedBlogImage) {
            await uploadBlogFile(res.data.data._id, submitBlogData)
              .then(() => {
                toast.success('Blog Updated!!!');
                getBlogs();
                setSubmitLoading(false);
              })
              .catch((e) => {
                setSubmitLoading(false);
                toast.error(e.message);
              });
          } else {
            setSubmitLoading(false);
            toast.success('Blog Updated!!!');
            getBlogs();
          }
        })
        .catch((e) => {
          setSubmitLoading(false);
          toast.error(e.message);
        });
    } else {
      createBlog(jsonData)
        .then((res) => {
          uploadBlogFile(res.data.data._id, submitBlogData)
            .then(() => {
              setSubmitLoading(false);
              toast.success('Blog Updated!!!');
              getBlogs();
            })
            .catch((e) => {
              setSubmitLoading(false);
              toast.error(e.message);
            });
        })
        .catch((e) => {
          setSubmitLoading(false);
          toast.error(e.message);
        });
    }
  };
  useEffect(() => {
    if (showModal && edit) {
      setSelectedBlogImage(null);
      setBlogImageUrl(edit.image && edit.image.medium);
    } else {
      setDefaultValues({ title: '', description: '', blogImage: '' });
    }
  }, [showModal]);
  return (
    <Modal
      open={showModal}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="lg-modal"
    >
      <Box sx={style}>
        <Stack spacing={3} className="titleStack">
          <Typography className="page_title">Write a Blog</Typography>
          <Typography className="cross_btn" onClick={toggleModal}>
            <Iconify icon="eva:close-outline" width={40} height={40} />
          </Typography>
        </Stack>
        <form methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={{ xs: 'column', sm: 'row' }} className="events_field_container" spacing={2}>
            {inputValues.map((input, index) => (
              <>
                {input.type === 'text' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    <TextField
                      key={`${input.name}-${index}`}
                      label={input.label}
                      error={
                        errors &&
                        errors[input.name] &&
                        errors[input.name].type === 'required' &&
                        `${input.label}  is required`
                      }
                      defaultValue={input.value ? input.value : ''}
                      style={{ width: '100%' }}
                      {...register(`${input.name}`, { required: true })}
                    />
                    {errors && `${input.name}` in errors && console.log('errors', errors[input.name].message)}
                  </React.Fragment>
                )}
                {input.type === 'textarea' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    <TextField
                      key={`${input.name}-${index}`}
                      label={input.label}
                      multiline
                      defaultValue={input.value ? input.value : ''}
                      rows={20}
                      error={
                        errors &&
                        errors[input.name] &&
                        errors[input.name].type === 'required' &&
                        `${input.label}  is required`
                      }
                      {...register(`${input.name}`, { required: true })}
                      style={{ width: '100%' }}
                    />
                    {errors && `${input.name}` in errors && console.log('errors', errors[input.name].message)}
                  </React.Fragment>
                )}
                {input.type === 'file' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    {blogImageUrl && (
                      <Stack mt={2} textAlign="center" className="image_preview blog_image">
                        <img
                          src={blogImageUrl.includes('http') ? blogImageUrl : process.env.REACT_APP_CDN + blogImageUrl}
                          alt={selectedBlogImage && selectedBlogImage.name}
                          height="100px"
                        />
                      </Stack>
                    )}
                    <input
                      accept="image/*"
                      type="file"
                      id={`${input.name}-${index}`}
                      style={{ display: 'none' }}
                      onInput={(e) => {
                        setSelectedBlogImage(e.target.files[0]);
                      }}
                      {...register(`${input.name}`, { required: !edit })}
                    />
                    <label htmlFor={`${input.name}-${index}`} className="select_label">
                      {!blogImageUrl && (
                        <div
                          className={`upload_image_container pointer ${
                            errors && errors[input.name] && errors[input.name].type === 'required' && 'error-file'
                          }`}
                        >
                          <Iconify icon="eva:cloud-upload-outline" width={40} height={40} />
                          <span>Upload Image</span>
                        </div>
                      )}
                      <Button variant="contained" color="primary" component="span" className="buttonContainer">
                        Browser Files
                      </Button>
                    </label>
                    {errors && `${input.name}` in errors && console.log('IMAGE!! ERRORS', errors[input.name].message)}
                  </React.Fragment>
                )}
              </>
            ))}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ marginTop: 20 }}
              loading={submitLoading}
            >
              Publish
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
