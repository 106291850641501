import { configureStore } from '@reduxjs/toolkit';
import events from './events';
import blogs from './blogs';
import auth from './auth';

export default configureStore({
  reducer: {
    events,
    auth,
    blogs,
  },
});
