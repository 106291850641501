import PropTypes from 'prop-types';
import { useEffect } from 'react';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  Select,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { categories } from '../../../utils/variables';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
// import { ColorManyPicker } from '../../../components/color-utils';

// ----------------------------------------------------------------------

ShopFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};
export default function ShopFilterSidebar({ isOpenFilter, onOpenFilter, onCloseFilter, filters, setFilters }) {
  console.log('filters:', filters);
  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={isOpenFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Statuses
              </Typography>
              <FormGroup>
                <FormControl>
                  {' '}
                  <FormControlLabel
                    key="rec-1"
                    control={
                      <Checkbox
                        checked={filters.isRecommended}
                        onChange={() => {
                          setFilters({ ...filters, isRecommended: !filters.isRecommended });
                        }}
                      />
                    }
                    label="Recommended"
                  />
                  <FormControlLabel
                    key="feat-2"
                    control={
                      <Checkbox
                        checked={filters.isFeatured}
                        onChange={() => {
                          setFilters({ ...filters, isFeatured: !filters.isFeatured });
                        }}
                      />
                    }
                    label="Featured"
                  />
                  <FormControlLabel
                    key="trend-3"
                    control={
                      <Checkbox
                        checked={filters.isTrending}
                        onChange={() => {
                          setFilters({ ...filters, isTrending: !filters.isTrending });
                        }}
                      />
                    }
                    label="Trending"
                  />
                </FormControl>
              </FormGroup>
            </div>

            <div>
              <Typography variant="subtitle1" gutterBottom>
                Category
              </Typography>
              <RadioGroup>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filters.category ? filters.category : ''}
                    label="Category"
                    onChange={(e) => {
                      const category = categories.find((cat) => e.target.value === cat.value).value;
                      setFilters({ ...filters, category });
                    }}
                  >
                    {categories.map((cat, index) => (
                      <MenuItem value={cat.value} key={index}>
                        {cat.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </RadioGroup>
            </div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
            onClick={(e) => {
              e.preventDefault();
              setFilters({
                category: '',
                startDate: '',
                endDate: '',
                isFeatured: null,
                isRecommended: null,
                isTrending: null,
              });
              onCloseFilter();
            }}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
