import { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import _, { filter } from 'lodash';
// material
import { Container, Button, Stack, Typography } from '@mui/material';
//  api
import { useSelector, useDispatch } from 'react-redux';
import { loadEvents } from '../store/api/api';
import { eventsReceived } from '../store/events';
// components
import Loader from '../components/loader';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/events';
import AddEvent from '../sections/@dashboard/events/AddEvent';

import EventSearch from '../components/searchInput';
// mock
import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const [openFilter, setOpenFilter] = useState(false);
  const [showAddEvent, setShowAddEvent] = useState(false);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [edit, setEdit] = useState(null);

  const [filters, setFilters] = useState({
    category: '',
    startDate: '',
    endDate: '',
    isFeatured: null,
    isRecommended: null,
    isTrending: null,
  });
  const [sort, setSort] = useState({ sort: '', order: '' });

  const ref = useRef(null);
  useEffect(() => {
    if (loading) {
      ref.current.continuousStart();
    } else {
      ref.current.complete();
    }
  }, [loading]);
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const events = useSelector((state) => state.events.events);
  const dispatch = useDispatch();
  const getEvents = async () => {
    setLoading(true);
    const payLoad = { keyword: search, ...filters, ...sort };
    const params = _.pickBy(payLoad, (value, key) => {
      return !(value === undefined || value === '' || value === null || value === {} || value === false);
    });
    const response = await loadEvents(params);
    setLoading(false);
    setEdit(null);
    setShowAddEvent(false);
    dispatch(eventsReceived(response));
  };
  useEffect(() => {
    getEvents();
  }, [search, filters, sort]);
  return (
    <Page title="Dashboard: Events">
      <Loader loaderRef={ref} />
      <Container>
        {showAddEvent ? (
          <>
            <AddEvent
              showModal={showAddEvent}
              toggleModal={() => {
                if (showAddEvent) setEdit(null);
                setShowAddEvent(!showAddEvent);
              }}
              getEvents={getEvents}
              edit={edit}
            />
          </>
        ) : (
          <>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" mb={5}>
              <Typography variant="h4" sx={{ mb: 5 }}>
                Events
              </Typography>
              <Button
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  setShowAddEvent(true);
                }}
              >
                New Event
              </Button>
            </Stack>
            <Stack
              direction="row"
              flexWrap="wrap-reverse"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 5 }}
            >
              <EventSearch posts={events} search={search} setSearch={setSearch} getEvents={getEvents} />
              <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                <ProductFilterSidebar
                  isOpenFilter={openFilter}
                  onOpenFilter={handleOpenFilter}
                  onCloseFilter={handleCloseFilter}
                  filters={filters}
                  setFilters={setFilters}
                />
                {/* <ProductSort sort={sort} setSort={setSort} /> */}
              </Stack>
            </Stack>

            <ProductList
              setEdit={(data) => {
                setEdit(data);
              }}
              products={events}
              getEvents={getEvents}
              toggleEditModal={() => setShowAddEvent(!showAddEvent)}
            />
          </>
        )}
      </Container>
    </Page>
  );
}
