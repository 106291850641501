import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
// // @mui
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';

// material
import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import DateTimePicker from '../../../components/dateTimePicker';
import { createEvents, multipleFileUpload, singleFileUpload, updateEvent } from '../../../store/api/api';
import { categories } from '../../../utils/variables';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddEvent({ getEvents, showModal, id, toggleModal, edit }) {
  const [selectedVenueImage, setSelectedVenueImage] = useState(null);
  const [selectedEventImage, setSelectedEventImage] = useState(null);
  const [venueImageUrl, setVenueImageUrl] = useState(null);
  const [eventImageUrl, setEventImageUrl] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateError, setDateError] = useState({ startDate: '', endDate: '' });

  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (selectedVenueImage) {
      setVenueImageUrl(URL.createObjectURL(selectedVenueImage));
    }
    if (selectedEventImage) {
      setEventImageUrl(URL.createObjectURL(selectedEventImage));
    }
  }, [selectedVenueImage, selectedEventImage]);
  const EventSchema = Yup.object().shape({
    title: Yup.string().required('title required'),
    description: Yup.string().required('description required'),
    bookingLinks: Yup.string().required('booking Links required'),
    hostName: Yup.string().required('host Name required'),
    hostBio: Yup.string().required('host Bio required'),
    hostInstagram: Yup.string().required('host Instagram required'),
    hostFacebook: Yup.string().required('host Facebook required'),
    hostLinkedIn: Yup.string().required('host LinkedIn required'),
    hostTwitter: Yup.string().required('host Twitter required'),
    eventInstagram: Yup.string().required('event Instagram required'),
    eventFacebook: Yup.string().required('event Facebook required'),
    eventLinkedIn: Yup.string().required('event LinkedIn required'),
    eventTwitter: Yup.string().required('even Twitter required'),
    eventImage: Yup.string().required('Event Image required'),
    venueName: Yup.string().required('venue Name required'),
    venueDescription: Yup.string().required('venue Description required'),
    venueLocationLink: Yup.string().required('venue Location Link required'),
    venueImage: Yup.string().required('venue Image required'),
    category: Yup.string().required('category required'),
  });
  let val = '';
  if (edit) {
    val = categories.find((cat) => cat.value === edit.category) === undefined ? '' : val.value;
  }
  const inputValues = [
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      value: edit ? edit.title : '',
      validationRules: { required: true },
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      value: edit ? edit.description : '',
      validationRules: { required: true },
    },
    {
      name: 'startDate',
      label: 'Start Date',
      type: 'date-local',
      value: edit ? new Date(edit.startDate) : '',
      validationRules: { required: true },
    },
    {
      name: 'endDate',
      label: 'End Date',
      type: 'date-local',
      value: edit ? new Date(edit.endDate) : '',
      validationRules: { required: true },
    },
    {
      name: 'bookingLinks',
      label: 'Booking Links',
      type: 'text',
      value: edit && edit.bookingLinks && edit.bookingLinks.length > 0 ? edit.bookingLinks[0] : '',
      validationRules: { required: true },
    },
    {
      name: 'About The Host',
      type: 'title',
      value: edit ? edit.title : '',
      validationRules: { required: false },
    },
    {
      name: 'hostName',
      label: 'Host Name',
      type: 'text',
      value: edit ? edit.hostName : '',
      validationRules: { required: true },
    },
    {
      name: 'hostBio',
      label: 'Host Bio',
      type: 'text',
      value: edit ? edit.hostBio : '',
      validationRules: { required: false },
    },
    {
      name: 'hostInstagram',
      label: 'Host Instagram',
      type: 'text',
      value: edit ? edit.hostSocialMediaLink.instagram : '',
      validationRules: { required: false },
    },
    {
      name: 'hostFacebook',
      label: 'Host Facebook',
      type: 'text',
      value: edit ? edit.hostSocialMediaLink.facebook : '',
      validationRules: { required: false },
    },
    {
      name: 'hostLinkedIn',
      label: 'Host LinkedIn',
      type: 'text',
      value: edit ? edit.hostSocialMediaLink.linkedIn : '',
      validationRules: { required: false },
    },
    {
      name: 'hostTwitter',
      label: 'Host Twitter',
      type: 'text',
      value: edit ? edit.hostSocialMediaLink.twitter : '',
      validationRules: { required: false },
    },
    {
      name: 'About The Event',
      type: 'title',
      value: edit ? edit.title : '',
      validationRules: { required: true },
    },
    {
      name: 'eventInstagram',
      label: 'Event Instagram',
      type: 'text',
      value: edit ? edit.eventSocialProfile.instagram : '',
      validationRules: { required: false },
    },
    {
      name: 'eventFacebook',
      label: 'Event Facebook',
      type: 'text',
      value: edit ? edit.eventSocialProfile.facebook : '',
      validationRules: { required: false },
    },
    {
      name: 'eventLinkedIn',
      label: 'Event LinkedIn',
      type: 'text',
      value: edit ? edit.eventSocialProfile.linkedIn : '',
      validationRules: { required: false },
    },
    {
      name: 'eventTwitter',
      label: 'Event Twitter',
      type: 'text',
      value: edit ? edit.eventSocialProfile.twitter : '',
      validationRules: { required: false },
    },
    {
      name: 'eventImage',
      label: 'Event Image',
      type: 'file',
      value: edit && edit.images && edit.images.length > 0 ? edit.images[0].medium : '',
      validationRules: { required: true },
    },
    {
      name: 'About The Venue',
      type: 'title',
      value: edit ? edit.title : '',
      validationRules: { required: true },
    },
    {
      name: 'venueName',
      label: 'Venue Name',
      type: 'text',
      value: edit ? edit.venue.name : '',
      validationRules: { required: true },
    },
    {
      name: 'venueDescription',
      label: 'Venue Description',
      type: 'textarea',
      value: edit ? edit.venue.description : '',
      validationRules: { required: true },
    },
    {
      name: 'venueLocationLink',
      label: 'Venue Location Link',
      type: 'text',
      value: edit ? edit.venue.locationLink : '',
      validationRules: { required: false },
    },

    {
      name: 'category',
      label: 'Category',
      type: 'select',
      value: edit ? categories.find((cat) => edit.category === cat.value).value : '',
      validationRules: { required: true },
    },
    {
      name: 'venueImage',
      label: 'Venue Image',
      type: 'files',
      value: edit && edit.venue.image ? edit.venue.image.medium : '',
      validationRules: { required: false },
    },
  ];
  const [defaultValues, setDefaultValues] = useState(
    edit
      ? {
          title: edit.title,
          description: edit.description,
          startDate: new Date(edit.startDate),
          endDate: new Date(edit.endDate),
          bookingLinks: edit.bookingLinks && edit.bookingLinks.length > 0 && edit.bookingLinks[0],
          hostName: edit.hostName,
          hostBio: edit.hostBio,
          hostInstagram: edit.hostSocialMediaLink.instagram,
          hostFacebook: edit.hostSocialMediaLink.facebook,
          hostLinkedIn: edit.hostSocialMediaLink.linkedIn,
          hostTwitter: edit.hostSocialMediaLink.twitter,
          eventInstagram: edit.eventSocialProfile.instagram,
          eventFacebook: edit.eventSocialProfile.facebook,
          eventLinkedIn: edit.eventSocialProfile.linkedIn,
          eventTwitter: edit.eventSocialProfile.twitter,
          eventImage: edit.images && edit.images.length > 0 && edit.images[0],
          venueName: edit.venue.name,
          venueDescription: edit.venue.description,
          venueLocationLink: edit.venue.locationLink,
          venueImage: edit.venue.image,
          category: categories.find((cat) => cat.value === edit.category),
        }
      : {
          title: '',
          description: '',
          startDate: '',
          endDate: '',
          bookingLinks: '',
          hostName: '',
          hostBio: '',
          hostInstagram: '',
          hostFacebook: '',
          hostLinkedIn: '',
          hostTwitter: '',
          eventInstagram: '',
          eventFacebook: '',
          eventLinkedIn: '',
          eventTwitter: '',
          eventImage: '',
          venueName: '',
          venueDescription: '',
          venueLocationLink: '',
          venueImage: '',
          category: '',
        }
  );

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!startDate) {
      await setDateError({ ...dateError, startDate: 'Error Required' });
    } else {
      await setDateError({ ...dateError, startDate: '' });
    }
    if (!endDate) {
      await setDateError({ ...dateError, endDate: 'Error Required' });
    } else {
      await setDateError({ ...dateError, endDate: '' });
    }
    if (
      startDate &&
      endDate &&
      Number(moment(new Date(startDate)).format('x')) >= Number(moment(new Date(endDate)).format('x'))
    ) {
      await setDateError({ ...dateError, endDate: 'End Date must be larger than Start Date' });
    } else {
      await setDateError({ ...dateError, endDate: '' });
    }

    if (dateError.startDate || dateError.endDate) return;
    const submitVenueData = new FormData();
    const submitEventsData = new FormData();
    const jsonData = {
      title: data.title,
      description: data.description,
      startDate: moment(new Date(startDate)).format('x'),
      endDate: moment(new Date(endDate)).format('x'),
      bookingLinks: [data.bookingLinks],

      hostName: data.hostName,
      hostBio: data.hostBio,
      hostSocialMediaLink: {
        instagram: data.hostInstagram,
        facebook: data.hostFacebook,
        linkedIn: data.hostLinkedIn,
        twitter: data.hostTwitter,
      },
      eventSocialProfile: {
        instagram: data.eventInstagram,
        facebook: data.eventFacebook,
        linkedIn: data.eventLinkedIn,
        twitter: data.eventTwitter,
      },
      venue: {
        name: data.venueName,
        description: data.venueDescription,
        locationLink: data.venueLocationLink,
        image: null,
      },
      category: data.category,
    };

    if (selectedVenueImage) submitVenueData.append(`file`, selectedVenueImage);
    if (selectedEventImage) submitEventsData.append(`files`, selectedEventImage);
    setSubmitLoading(true);
    if (edit) {
      updateEvent(edit._id, jsonData)
        .then(async (res) => {
          if (selectedVenueImage) {
            await singleFileUpload(res.data.data._id, submitVenueData)
              .then(() => {
                if (!selectedEventImage) {
                  setSubmitLoading(false);
                  toast.success('Event Updated!!!');
                  getEvents();
                }
              })
              .catch((e) => {
                setSubmitLoading(false);
                toast.error(e.message);
              });
          }
          if (selectedEventImage) {
            multipleFileUpload(res.data.data._id, submitEventsData).then(() => {
              setSubmitLoading(false);
              toast.success('Event Updated!!!');
              getEvents();
            });
          } else if (!selectedVenueImage) {
            toast.success('Event Updated!!!');
            setSubmitLoading(false);
            getEvents();
          }
        })
        .catch((e) => {
          setSubmitLoading(false);
          toast.error(e.message);
        });
    } else {
      createEvents(jsonData)
        .then((res) => {
          singleFileUpload(res.data.data._id, submitVenueData)
            .then(() => {
              setSubmitLoading(false);
            })
            .catch((e) => {
              setSubmitLoading(false);
              toast.error(e.message);
            });
          multipleFileUpload(res.data.data._id, submitEventsData).then(() => {
            setSubmitLoading(false);
            toast.success('Event Created!!!');
            getEvents();
          });
        })
        .catch((e) => {
          setSubmitLoading(false);
          toast.error(e.message);
        });
    }
  };
  useEffect(() => {
    if (showModal && edit) {
      setSelectedVenueImage(null);
      setSelectedEventImage(null);
      setEventImageUrl(edit.images && edit.images.length > 0 && edit.images[0].medium);
      setVenueImageUrl(edit.venue.image && edit.venue.image.medium);
      setStartDate(new Date(edit.startDate));
      setEndDate(new Date(edit.endDate));
    }
  }, [showModal]);
  return (
    <Modal
      open={showModal}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="lg-modal"
    >
      <Box sx={style}>
        <Stack spacing={3} className="titleStack">
          <Typography className="page_title">{edit ? 'Edit' : 'Create an'} Event</Typography>
          <Typography className="cross_btn" onClick={toggleModal}>
            <Iconify icon="eva:close-outline" width={40} height={40} />
          </Typography>
        </Stack>
        <form methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={{ xs: 'column', sm: 'row' }} className="events_field_container" spacing={2}>
            {inputValues.map((input, index) => (
              <>
                {input.type === 'title' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    <Typography
                      style={{
                        width: '100%',
                        marginTop: '3rem',
                        marginLeft: 0,
                        margin: '30px 0 30px 0',
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {input.name}
                    </Typography>
                  </React.Fragment>
                )}
                {input.type === 'text' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    {input.name === 'bookingLinks' ? (
                      <>
                        <TextField
                          key={`${input.name}-${index}`}
                          error={
                            errors &&
                            errors[input.name] &&
                            errors[input.name].type === 'required' &&
                            `${input.label}  is required`
                          }
                          defaultValue={input.value ? input.value : ''}
                          label={input.label}
                          style={{ width: '49%' }}
                          // ref={register}
                          {...register(`${input.name}`, input.validationRules)}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          key={`${input.name}-${index}`}
                          label={input.label}
                          error={
                            errors &&
                            errors[input.name] &&
                            errors[input.name].type === 'required' &&
                            `${input.label}  is required`
                          }
                          defaultValue={input.value ? input.value : ''}
                          style={{ width: '49%' }}
                          {...register(`${input.name}`, input.validationRules)}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
                {input.type === 'textarea' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    <TextField
                      key={`${input.name}-${index}`}
                      label={input.label}
                      multiline
                      defaultValue={input.value ? input.value : ''}
                      rows={4}
                      error={
                        errors &&
                        errors[input.name] &&
                        errors[input.name].type === 'required' &&
                        `${input.label}  is required`
                      }
                      {...register(`${input.name}`, input.validationRules)}
                      style={{ width: '100%' }}
                    />
                  </React.Fragment>
                )}
                {input.type === 'date-local' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    <DateTimePicker
                      id={`datetime-local-${index}`}
                      label={`${input.label}`}
                      type="datetime-local"
                      value={input.name === 'startDate' ? startDate : endDate}
                      setValue={input.name === 'startDate' ? setStartDate : setEndDate}
                      defaultValue={input.value ? input.value : ''}
                      name={input.label}
                    />
                  </React.Fragment>
                )}
                {input.type === 'select' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    <TextField
                      select
                      fullWidth
                      error={
                        errors &&
                        errors[input.name] &&
                        errors[input.name].type === 'required' &&
                        `${input.label}  is required`
                      }
                      defaultValue={input.value ? input.value : ''}
                      label={`${input.label}`}
                      inputProps={register(`${input.name}`, {
                        required: 'Please enter Category',
                      })}
                      style={{ width: '49%' }}
                    >
                      {categories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </React.Fragment>
                )}
                {input.type === 'file' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    {eventImageUrl && (
                      <Stack mt={2} textAlign="center" className="image_preview">
                        <img
                          src={
                            eventImageUrl.includes('http') ? eventImageUrl : process.env.REACT_APP_CDN + eventImageUrl
                          }
                          alt={selectedEventImage && selectedEventImage.name}
                          height="100px"
                        />
                      </Stack>
                    )}
                    <input
                      accept="image/*"
                      type="file"
                      id={`${input.name}-${index}`}
                      style={{ display: 'none' }}
                      onInput={(e) => {
                        setSelectedEventImage(e.target.files[0]);
                      }}
                      {...register(`${input.name}`, { required: !edit })}
                    />
                    <label htmlFor={`${input.name}-${index}`} className="select_label">
                      {!eventImageUrl && (
                        <div
                          className={`upload_image_container pointer ${
                            errors && errors[input.name] && errors[input.name].type === 'required' && 'error-file'
                          }`}
                        >
                          <Iconify icon="eva:cloud-upload-outline" width={40} height={40} />
                          <span>Upload Image</span>
                        </div>
                      )}
                      <Button variant="contained" color="primary" component="span" className="buttonContainer">
                        Browser Files
                      </Button>
                    </label>
                  </React.Fragment>
                )}
                {input.type === 'files' && (
                  <React.Fragment key={`${input.name}-${index}`}>
                    {venueImageUrl && (
                      <Stack mt={2} textAlign="center" className="image_preview">
                        <img
                          src={
                            venueImageUrl.includes('http') ? venueImageUrl : process.env.REACT_APP_CDN + venueImageUrl
                          }
                          alt={selectedVenueImage && selectedVenueImage.name}
                          height="100px"
                        />
                      </Stack>
                    )}
                    <input
                      accept="image/*"
                      type="file"
                      id={`${input.name}-${index}`}
                      style={{ display: 'none' }}
                      onInput={(e) => {
                        setSelectedVenueImage(e.target.files[0]);
                      }}
                      {...register(`${input.name}`, { required: !edit })}
                    />
                    <label htmlFor={`${input.name}-${index}`} className="select_label">
                      {!venueImageUrl && (
                        <div
                          className={`upload_image_container pointer ${
                            errors && errors[input.name] && errors[input.name].type === 'required' && 'error-file'
                          }`}
                        >
                          <Iconify icon="eva:cloud-upload-outline" width={40} height={40} />
                          <span>Upload Image</span>
                        </div>
                      )}
                      <Button variant="contained" color="primary" component="span">
                        Browser Files
                      </Button>
                    </label>
                  </React.Fragment>
                )}
              </>
            ))}{' '}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ marginTop: 20 }}
              loading={submitLoading}
            >
              Register
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
