import { useRef, useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { deleteEvent, loadEvents, changeStatus } from '../../../store/api/api';

//  Components
// import EditPopup from '../../../components/popup/EditPopup';

import AlertModal from '../../../components/CustomModal';
// material
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ id, product, getEvents, setEdit, toggleEditModal }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setLoading(true);
    const response = await deleteEvent(id);
    getEvents();
    setLoading(false);
    setShowModal(false);
  };
  return (
    <>
      <IconButton
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        className="more-icon"
      >
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        PaperProps={{
          sx: { width: 220, maxWidth: '100%', left: 57 },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className=""
      >
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={async (e) => {
            e.stopPropagation();
            await setEdit(product);
            toggleEditModal();

            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>{' '}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={async (e) => {
            e.stopPropagation();

            await changeStatus(id, { isFeatured: !product.isFeatured }).then(() => {
              getEvents();
            });
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:star-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={`${product.isFeatured ? 'Remove' : 'Mark'} as Featured`}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>{' '}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={async (e) => {
            e.stopPropagation();

            await changeStatus(id, { isRecommended: !product.isRecommended }).then(() => {
              getEvents();
            });
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:bookmark-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={`${product.isRecommended ? 'Remove' : 'Mark'} as Recommended`}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>{' '}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={async (e) => {
            e.stopPropagation();

            await changeStatus(id, { isTrending: !product.isTrending }).then(() => {
              getEvents();
            });
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trending-up-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={`${product.isTrending ? 'Remove' : 'Mark'} as Trending`}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(true);
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
      <AlertModal
        open={showModal}
        handleClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowModal(false);
        }}
        onSubmit={handleDelete}
        id={id}
        title="Alert!"
        description="Are you sure you want to delete this event?"
      />
    </>
  );
}
