import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import EventMoreMenu from './EventMoreMenu';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  cursor: 'pointer',
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product, getEvents, setEdit, toggleEditModal }) {
  const navigate = useNavigate();
  const { title, cover, price, colors, status, priceSale, _id, images } = product;
  return (
    <Card>
      <Box
        sx={{ pt: '100%', position: 'relative' }}
        onClick={() => {
          navigate(`/dashboard/event-details`, { state: product });
        }}
      >
        <ProductImgStyle
          alt={title}
          src={process.env.REACT_APP_CDN + (images && images.length > 0 && images[0].medium)}
        />
        <EventMoreMenu
          setEdit={setEdit}
          toggleEditModal={toggleEditModal}
          product={product}
          id={_id}
          getEvents={getEvents}
        />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ textTransform: 'capitalize', cursor: 'pointer' }}
            onClick={() => {
              navigate(`/dashboard/event-details`, { state: product });
            }}
          >
            {product.title}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: 'text.disabled',
              textTransform: 'capitalize',
            }}
            noWrap
          >
            {product.category}
          </Typography>{' '}
          <Typography
            variant="caption"
            sx={{
              color: 'text.disabled',
            }}
            noWrap
          >
            {moment(product.startDate).format('Do MMM YYYY')} - {moment(product.endDate).format('Do MMM YYYY')}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
