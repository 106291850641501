import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, IconButton, InputAdornment, Stack } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
//  api
import { auth } from '../../../store/api/api';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });
  const defaultValues = {
    email: 'demo@arpesh.in',
    password: 'Demo@123',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    auth({ email: data.email, password: data.password })
      .then((res) => {
        localStorage.setItem('admin', JSON.stringify(res.data.message));
        localStorage.setItem('x-auth-token', res.data.message.jwtToken);
        localStorage.setItem('x-refresh-token', res.data.message.refreshToken);
        navigate('/dashboard/events', { replace: true });
        toast.success('Signed In Successfully');
        setLoading(true);
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
        Login
      </LoadingButton>
      <Alert severity="info" variant="standard" sx={{ marginTop: 5, border: '1px solid #103996' }}>
        <AlertTitle>Demo Credentials</AlertTitle>
        Please use the <b>demo@arpesh.in</b> as email and <b>Demo@123</b> as password.
      </Alert>
    </FormProvider>
  );
}
