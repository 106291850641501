import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
  name: "events",
  initialState: {
    events: [],
  },
  reducers: {
    eventsReceived: (state, action) => {
      state.events = action.payload;
    },
  },
});

// this is for dispatch
export const { eventsReceived } = eventSlice.actions;

// this is for configureStore
export default eventSlice.reducer;
