import { useRef, useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { deleteBlog, loadBlogs } from '../../../store/api/api';

//  Components
import AlertModal from '../../../components/CustomModal';
// material
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ id, getBlogs, setEdit, toggleEditModal, post }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setLoading(true);
    const response = await deleteBlog(id);
    getBlogs();
    setLoading(false);
    setShowModal(false);
  };
  return (
    <>
      <IconButton
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        className="more-icon"
      >
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className=""
      >
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={async (e) => {
            e.stopPropagation();
            await setEdit(post);
            toggleEditModal();
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(true);
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
      <AlertModal
        open={showModal}
        handleClose={(e) => {
          e.stopPropagation();
          setShowModal(false);
        }}
        onSubmit={handleDelete}
        id={id}
        title="Alert!"
        description="Are you sure you want to delete this blog?"
      />
    </>
  );
}
