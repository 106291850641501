import PropTypes from 'prop-types';
// material
import { Typography, Box, Stack, Modal, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function MenuPopover({ open, id, handleClose, title, description, loading, onSubmit }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        <LoadingButton type="submit" variant="contained" loading={loading} onClick={onSubmit}>
          Delete
        </LoadingButton>
        <Button variant="text" sx={{ mx: 2 }} onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
