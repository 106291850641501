import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';
// material
import { Button, Container, Grid, Stack, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { loadBlogs } from '../store/api/api';
import { blogsReceived } from '../store/blogs';

// components
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Loader from '../components/loader';
import { BlogPostCard, BlogPostsSearch } from '../sections/@dashboard/blog';
import AddBlog from '../sections/@dashboard/blog/AddBlog';
// mock
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Blog() {
  const [loading, setLoading] = useState(false);
  const [showAddBlog, setShowAddBlog] = useState(false);
  const [edit, setEdit] = useState(null);
  const [search, setSearch] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    if (loading) {
      ref.current.continuousStart();
    } else {
      ref.current.complete();
    }
  }, [loading]);
  const blogs = useSelector((state) => state.blogs.blogs);
  const dispatch = useDispatch();

  const getBlogs = async () => {
    setLoading(true);
    const payLoad = { keyword: search };
    const params = _.pickBy(payLoad, (value, key) => {
      return !(value === undefined || value === '' || value === null || value === {});
    });
    const response = await loadBlogs(params);
    setLoading(false);
    setShowAddBlog(false);
    dispatch(blogsReceived(response));
  };
  useEffect(() => {
    getBlogs();
  }, [search]);

  return (
    <Page title="Dashboard: Blog">
      <Loader loaderRef={ref} />
      <Container>
        {showAddBlog ? (
          <AddBlog
            showModal={showAddBlog}
            toggleModal={() => {
              if (showAddBlog) setEdit(null);
              setShowAddBlog(!showAddBlog);
            }}
            getBlogs={getBlogs}
            edit={edit}
          />
        ) : (
          <>
            {' '}
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Blog
              </Typography>
              <Button
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  setShowAddBlog(true);
                }}
              >
                New Blog
              </Button>
            </Stack>
            <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
              <BlogPostsSearch posts={blogs} search={search} setSearch={setSearch} getBlogs={getBlogs} />
            </Stack>
            <Grid container spacing={3}>
              {blogs.map((post, index) => (
                <BlogPostCard
                  setEdit={(data) => {
                    setEdit(data);
                  }}
                  key={post.id}
                  toggleEditModal={() => setShowAddBlog(!showAddBlog)}
                  post={post}
                  getBlogs={getBlogs}
                  index={index}
                />
              ))}
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
}
