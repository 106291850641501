import axios from 'axios';
import { enIE } from 'date-fns/locale';

//  Auth
export const auth = async (data) => {
  try {
    const response = await axios({
      method: 'post',
      data,
      url: `${process.env.REACT_APP_API}auth/admins`,
    });
    return response;
  } catch (e) {
    throw e.response.data;
  }
};

//  Events
export const loadEvents = async (params) => {
  const headers = {
    jwtToken: localStorage.getItem('x-auth-token'),
  };
  try {
    const response = await axios({
      method: 'get',
      params,
      headers,
      url: `${process.env.REACT_APP_API}events/`,
    });
    return response.data.data;
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (e.response.data.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    throw e.response.data;
  }
};
export const deleteEvent = async (id) => {
  const headers = {
    jwtToken: localStorage.getItem('x-auth-token'),
  };
  try {
    const response = await axios({
      method: 'delete',
      headers,
      url: `${process.env.REACT_APP_API}events/${id}`,
    });
    return response;
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    throw e.response.data;
  }
};
export const createEvents = async (data) => {
  try {
    // console.log("DDD", data)
    const token = localStorage.getItem('x-auth-token');
    const response = await axios({
      method: 'post',
      data,
      headers: { jwtToken: token },
      url: `${process.env.REACT_APP_API}events/`,
    });
    return response;
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};

export const singleFileUpload = async (id, data) => {
  try {
    // console.log("DDD", data)
    const token = localStorage.getItem('x-auth-token');
    const response = await axios({
      method: 'post',
      headers: { jwtToken: token },
      data,
      url: `${process.env.REACT_APP_API}events/uploadFile/venue/${id}`,
    });
    return response;
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};
export const multipleFileUpload = async (id, data) => {
  try {
    // console.log("DDD", data)
    const token = localStorage.getItem('x-auth-token');
    const response = await axios({
      method: 'post',
      headers: { jwtToken: token },
      data,
      url: `${process.env.REACT_APP_API}events/uploadFiles/event/${id}`,
    });
    return response;
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};
export const fetchEvent = async (id) => {
  const token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios({
      method: 'put',
      headers: { jwtToken: token },
      // data,
      url: `${process.env.REACT_APP_API}events/${id}`,
    });
    return response;
  } catch (e) {
    console.log('Error', e);
    throw e;
  }
};
export const updateEvent = async (id, data) => {
  const token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API}events/${id}`,
      data,
      headers: { jwtToken: token },
    });
    return response;
  } catch (e) {
    console.log('Error', e);
    throw e;
  }
};

export const changeStatus = async (id, data) => {
  const token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API}events/changeStatuses/${id}`,
      data,
      headers: { jwtToken: token },
    });
    return response;
  } catch (e) {
    console.log('Error', e);
    throw e;
  }
};

//  Blogs
export const loadBlogs = async (params) => {
  const headers = {
    jwtToken: localStorage.getItem('x-auth-token'),
  };
  try {
    const response = await axios({
      method: 'get',
      params,
      headers,
      url: `${process.env.REACT_APP_API}blogs/`,
    });
    return response.data.data;
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    throw e.response.data;
  }
};
export const deleteBlog = async (id) => {
  const headers = {
    jwtToken: localStorage.getItem('x-auth-token'),
  };
  try {
    const response = await axios({
      method: 'delete',
      headers,
      url: `${process.env.REACT_APP_API}blogs/${id}`,
    });
    return response;
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (e.response.data.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    throw e.response.data;
  }
};
export const createBlog = async (data) => {
  const token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios({
      method: 'post',
      data,
      headers: { jwtToken: token },
      url: `${process.env.REACT_APP_API}blogs/`,
    });
    return response;
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};
export const updateBlog = async (id, data) => {
  const token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API}blogs/${id}`,
      data,
      headers: { jwtToken: token },
    });
    return response;
  } catch (e) {
    console.log('Error', e);
    throw e;
  }
};
export const uploadBlogFile = async (id, data) => {
  const token = localStorage.getItem('x-auth-token');
  try {
    // console.log("DDD", data)
    const response = await axios({
      method: 'post',
      headers: { jwtToken: token },
      data,
      url: `${process.env.REACT_APP_API}blogs/uploadFile/${id}`,
    });
    return response;
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};
