import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: [],
  },
  reducers: {
    authReceived: (state, action) => {
      state.auth = action.payload;
    },
  },
});

// this is for dispatch
export const { authReceived } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
