import { createSlice } from '@reduxjs/toolkit';

export const blogSlice = createSlice({
  name: 'blogs',
  initialState: {
    blogs: [],
  },
  reducers: {
    blogsReceived: (state, action) => {
      state.blogs = action.payload;
    },
  },
});

// this is for dispatch
export const { blogsReceived } = blogSlice.actions;

// this is for configureStore
export default blogSlice.reducer;
