import PropTypes from 'prop-types';
// material
import { Typography, Box, Stack, Modal, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { rest } from 'lodash';
import moment from 'moment';

export default function DatePicker({ id, label, type, value, setValue, errors, ...rest }) {
  const handleChange = (e) => {
    setValue(e);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        id={id}
        label={label}
        type={type}
        onChange={handleChange}
        value={value}
        // minDateTime={moment()}
        renderInput={(params) => (
          <TextField {...rest} {...params} className={errors ? 'date-input-error' : 'date-input-custom'} />
        )}
      />
    </LocalizationProvider>
  );
}
