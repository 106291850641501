import React from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import Iconify from '../components/Iconify';


const BlogDetails = () => {
    const { state } = useLocation();
    console.log("Blog:", state)
    const { eventSocialProfile, hostSocialMediaLink } = state;
    return (
        <section className="blog-details spad">
            <div className="container">
                <div className="">
                    <div className="col-lg-12">
                        <div className="blog__details__content">
                            <div className="blog__details__item">
                                <div
                                    className="blog__details__item__pic set-bg"
                                    // data-setbg={
                                    //   process.env.REACT_APP_CDN +
                                    //   (state.image && state.image.medium)
                                    // }
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_CDN +
                                            (state.image && state.image && state.image.large)
                                            })`,
                                    }}
                                >
                                    <a href="#">
                                        <i className="fa fa-share-alt" />
                                    </a>
                                </div>
                                <div className="blog__details__item__text">
                                    {" "}
                                    <div className="blog__details__item__widget">
                                        <div className="">
                                            <div className="col-lg-6">
                                                <ul>
                                                    <li>
                                                        {moment(state.updatedAt).format("MMM DD, YYYY")}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>{state.title}</h3>
                                </div>
                            </div>
                            <div className="blog__details__desc">
                                <p>{state.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetails