import React from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import Iconify from '../components/Iconify';


const EventDetails = () => {
    const { state } = useLocation();
    console.log("EVENT:", state)
    const { eventSocialProfile, hostSocialMediaLink } = state;
    return (
        <>
            {/* <Header /> */}
            {/* <Loader loader={false} /> */}
            <div className="events-single-container">
                <div
                    className="events-hero-bg"
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_CDN +
                            (state.images && state.images[0] && state.images[0].medium)
                            })`,
                    }}
                > </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="es-content-box">
                                <div className="es-upper d-lg-flex flex-lg-wrap">
                                    <div className="esu-left">
                                        <div className="featured-image">
                                            <img
                                                src={
                                                    process.env.REACT_APP_CDN +
                                                    (state.images &&
                                                        state.images[0] &&
                                                        state.images[0].large)
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="esu-right">
                                        <div className="es-content">
                                            <div className="esc-text-box">
                                                <div className="esc-img">
                                                    <ListItemIcon>
                                                        <Iconify icon="eva:calendar-outline" width={24} height={24} />
                                                    </ListItemIcon>
                                                </div>
                                                {moment(state.startDate).format("ddd, MMM DD, h:mma")} -{" "}
                                                {moment(state.endDate).format("ddd, MMM DD, h:mma")}
                                            </div>
                                            <h4 className="capitalize">{state.title}</h4>
                                            <h6 className="capitalize">{state.category}</h6>
                                            {state.hostName && (
                                                <div className="speakers-list speakers-list-1 d-flex flex-wrap">
                                                    <button
                                                        // role="button"
                                                        className="pointer "
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            window.open(state.venue.locationLink);
                                                        }}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                            window.open(state.venue.locationLink);
                                                        }}
                                                    >
                                                        Hosted By <strong>{state.hostName}</strong>
                                                        {state.hostBio && <p>{state.hostBio}</p>}
                                                    </button>
                                                </div>
                                            )}

                                            {hostSocialMediaLink && (
                                                <div className="speakers-list speakers-list-1 flex-wrap">
                                                    <h4 className="capitalize">Host's Social Media</h4>
                                                    <ul className="social-link">
                                                        {hostSocialMediaLink.facebook && (
                                                            <li>
                                                                <a
                                                                    href={hostSocialMediaLink.facebook}
                                                                    target="_blank" rel="noreferrer"
                                                                    className="facebook"
                                                                >
                                                                    <ListItemIcon>
                                                                        <Iconify icon="eva:facebook-outline" width={24} height={24} />
                                                                    </ListItemIcon>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {hostSocialMediaLink.twitter && (
                                                            <li>
                                                                <a
                                                                    href={hostSocialMediaLink.twitter}
                                                                    target="_blank" rel="noreferrer"
                                                                    className="twitter"
                                                                >
                                                                    <ListItemIcon>
                                                                        <Iconify icon="eva:twitter-outline" width={24} height={24} />
                                                                    </ListItemIcon>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {hostSocialMediaLink.instagram && (
                                                            <li>
                                                                <a
                                                                    href={hostSocialMediaLink.instagram}
                                                                    target="_blank" rel="noreferrer"
                                                                    className="instagram"
                                                                >
                                                                    <ListItemIcon>
                                                                        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" /></svg>
                                                                    </ListItemIcon>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {hostSocialMediaLink.linkedIn && (
                                                            <li>
                                                                <a
                                                                    href={hostSocialMediaLink.linkedIn}
                                                                    target="_blank" rel="noreferrer"
                                                                    className="instagram"
                                                                >
                                                                    <ListItemIcon>
                                                                        <Iconify icon="eva:linkedin-outline" width={24} height={24} />
                                                                    </ListItemIcon>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                            {state.venue && (
                                                <div className="speakers-list d-flex flex-wrap">
                                                    <button
                                                        // role="button"
                                                        // tabIndex={2}
                                                        className="pointer "
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            window.open(state.venue.locationLink);
                                                        }}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                            window.open(state.venue.locationLink);
                                                        }}
                                                    >
                                                        <h4 className="capitalize">Venue</h4>

                                                        <div className="speakers-box d-flex flex-wrap">
                                                            <div className="speakers-img-box">
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_CDN +
                                                                        (state.venue &&
                                                                            state.venue.image &&
                                                                            state.venue.image.small)
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="speakers-text-box">
                                                                <strong>
                                                                    {state.venue && state.venue.name}
                                                                </strong>
                                                                <p>{state.venue && state.venue.description}</p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            )}
                                            {state.bookingLinks && state.bookingLinks.length > 0 && (
                                                <div className="es-btn-box">
                                                    <a
                                                        href={state.bookingLinks[0]}
                                                        className="btn btn-orange btn-lg btn-block"
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        Join Event
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="es-lower d-lg-flex flex-lg-wrap">
                                    <div className="esl-left">
                                        <p>{state.description}</p>

                                        <ul className="social-link">
                                            {eventSocialProfile.facebook && (
                                                <li>
                                                    <a
                                                        href={eventSocialProfile.facebook}
                                                        target="_blank" rel="noreferrer"
                                                        className="facebook"
                                                    >
                                                        <ListItemIcon>
                                                            <Iconify icon="eva:facebook-outline" width={24} height={24} />
                                                        </ListItemIcon>
                                                    </a>
                                                </li>
                                            )}
                                            {eventSocialProfile.twitter && (
                                                <li>
                                                    <a
                                                        href={eventSocialProfile.twitter}
                                                        target="_blank" rel="noreferrer"
                                                        className="twitter"
                                                    >
                                                        <ListItemIcon>
                                                            <Iconify icon="eva:twitter-outline" width={24} height={24} />
                                                        </ListItemIcon>
                                                    </a>
                                                </li>
                                            )}
                                            {eventSocialProfile.instagram && (
                                                <li>
                                                    <a
                                                        href={eventSocialProfile.instagram}
                                                        target="_blank" rel="noreferrer"
                                                        className="instagram"
                                                    >
                                                        <ListItemIcon>
                                                            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" /></svg>
                                                        </ListItemIcon>
                                                    </a>
                                                </li>
                                            )}
                                            {eventSocialProfile.linkedIn && (
                                                <li>
                                                    <a
                                                        href={eventSocialProfile.linkedIn}
                                                        target="_blank" rel="noreferrer"
                                                        className="instagram"
                                                    >
                                                        <ListItemIcon>
                                                            <Iconify icon="eva:linkedin-outline" width={24} height={24} />
                                                        </ListItemIcon>
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventDetails