import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
import BlogsMoreMenu from './BlogsMoreMenu';
// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  cursor: 'pointer',
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const TitleStyle = styled(Link)({
  cursor: 'pointer',
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BlogPostCard({ post, index, getBlogs, setEdit, toggleEditModal }) {
  const { image, title, description, createdAt, _id } = post;
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card
        sx={{ position: 'relative' }}
        onClick={() => {
          navigate(`/dashboard/blog-details`, { state: post });
        }}
      >
        <CardMediaStyle
          sx={{
            pt: 'calc(100% * 4 / 3)',
            '&:after': {
              top: 0,
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }}
        >
          <CoverImgStyle alt={title} src={process.env.REACT_APP_CDN + (image && image.medium)} />
        </CardMediaStyle>
        <BlogsMoreMenu id={_id} getBlogs={getBlogs} setEdit={setEdit} toggleEditModal={toggleEditModal} post={post} />

        <CardContent
          sx={{
            pt: 4,
            bottom: 0,
            width: '100%',
            position: 'absolute',
          }}
        >
          <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
            {fDate(createdAt)}
          </Typography>

          <TitleStyle
            to="#"
            color="inherit"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
            sx={{
              typography: 'h5',
              height: 60,
              color: 'common.white',
            }}
          >
            {title}
          </TitleStyle>
        </CardContent>
      </Card>
    </Grid>
  );
}
