import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
// import Products from './pages/Products';
import Events from './pages/Events';
import DashboardApp from './pages/DashboardApp';
import EventDetails from './pages/EventDetails';
import BlogDetails from './pages/BlogDetails';

// ----------------------------------------------------------------------

export default function Router() {
  const auth = localStorage.getItem('x-auth-token');

  return useRoutes([
    {
      path: '/dashboard',
      element: auth ? <DashboardLayout /> : <Navigate to={'/login'} />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        { path: 'events', element: <Events /> },
        { path: 'blog', element: <Blog /> },
        { path: 'event-details', element: <EventDetails /> },
        { path: 'blog-details', element: <BlogDetails /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to={auth ? '/dashboard/events' : '/login'} /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
