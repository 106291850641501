export const categories = [
  {
    value: 'clubnight',
    label: 'Clubnight',
  },
  {
    value: 'festival',
    label: 'Festival',
  },
  {
    value: 'psychedelic',
    label: 'Psychedelic',
  },
  {
    value: 'deep-house',
    label: 'Deep house',
  },
  {
    value: 'techno',
    label: 'Techno',
  },
  {
    value: 'trance',
    label: 'Trance',
  },
  {
    value: 'hip-hop',
    label: 'Hip-hop',
  },
  {
    value: 'commercial',
    label: 'Commercial',
  },
  {
    value: 'EDM',
    label: 'EDM',
  },
  {
    value: 'outdoor',
    label: 'Outdoor',
  },
];
